import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import Link from 'next/link';

import { useAuth, useSignIn } from '@clerk/nextjs';

import { zodResolver } from '@hookform/resolvers/zod';

import { useUserAuth } from '@/context/user-auth-provider';
import { z } from 'zod';

import { useVerifyPermission } from '@@/Authentication/hooks';
import { ClerkError, ClerkErrorToMessage } from '@@/Authentication/integration/clerk';

import { useNavigationFlow } from '@/hooks/use-navigation-flow';

import { PARAM_ORIGIN, ROUTES } from '@/constants';

import Button from '@/components/button';
import Container from '@/components/container';
import FullScreenSpinner from '@/components/full-screen-spinner';
import InputPassword from '@/components/input-password';
import InputWithLabel from '@/components/input-with-label';
import Typograph from '@/components/typography';

type LoginForm = {
  email: string;
  password: string;
};

const prefix = 'login.form';

type Props = {
  userEmail?: string;
};

const LoginPageView = ({ userEmail }: Props) => {
  const { isSignedIn: isClerkSignedIn } = useAuth();
  const { isLoaded, setActive, signIn } = useSignIn();
  const { cleanLegacyData, isSignedIn } = useUserAuth();
  const { isOnlyAdmin } = useVerifyPermission();
  const { nextPage } = useNavigationFlow();

  const [isLoading, setIsLoading] = useState(false);

  const { formatMessage } = useIntl();
  const loginFormsSchema = z.object({
    email: z
      .string()
      .min(1, formatMessage({ id: `${prefix}.email.invalid` }))
      .email(formatMessage({ id: `${prefix}.email.invalid` }))
      .trim(),
    password: z
      .string()
      .min(8, formatMessage({ id: `${prefix}.password.min-length` }))
      .trim(),
  });

  type LoginFormsValues = z.infer<typeof loginFormsSchema>;

  const handleNextPage = () => {
    if (isSignedIn && isClerkSignedIn && isOnlyAdmin) {
      nextPage(ROUTES.ONLY_ADMIN);
      return;
    } else nextPage(ROUTES.OPERATOR_PORTAL, PARAM_ORIGIN.LOGIN);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    clearErrors,
  } = useForm<LoginFormsValues>({
    resolver: zodResolver(loginFormsSchema),
    defaultValues: {
      email: userEmail,
    },
  });

  useEffect(() => {
    if (isSignedIn) {
      handleNextPage();
    }
  }, [isSignedIn]);

  const onSubmit = async (formData: LoginForm) => {
    setIsLoading(true);
    try {
      const result = await signIn?.create({
        identifier: formData.email,
        password: formData.password,
      });

      if (result?.status === 'complete' && setActive) {
        clearErrors();
        cleanLegacyData();

        await setActive({ session: result.createdSessionId })
          .then(() => {
            handleNextPage();
            return;
          })
          .catch((err) => {
            console.error(err);
            setError('password', {
              type: 'validate',
              message: 'Senha ou e-mail incorretos.',
            });
          });
      }
    } catch (err) {
      setIsLoading(false);

      const code = (err as ClerkError).errors[0].code;

      const message = ClerkErrorToMessage[code] ?? 'Senha ou e-mail incorretos.';

      setError('password', {
        type: 'validate',
        message,
      });
    }
  };

  if (!isLoaded) return null;

  return (
    <Container withoutHeader skipUserAuthCheck>
      <div className="mb-6 pt-2">
        <Typograph.H1 variant="h2" className="mb-2">
          Entre na sua conta para fazer uma troca!
        </Typograph.H1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <InputWithLabel
          label={formatMessage({ id: `${prefix}.email` })}
          className="mb-3"
          autoComplete="email"
          placeholder="Digite seu e-mail"
          type="email"
          errorMessage={errors.email?.message ?? ''}
          isRequired
          {...register('email')}
        />

        <InputPassword className="mb-3" errorMessage={errors.password?.message ?? ''} {...register('password')} />

        <div className="py-4">
          <Link href={`${ROUTES.FORGOT_PASSWORD}?email=${watch('email')}`}>
            <Typograph.Span variant="link">Esqueceu a senha?</Typograph.Span>
          </Link>
        </div>

        <Button className="w-full" type="submit">
          <FormattedMessage id="enter" />
        </Button>
      </form>

      <FullScreenSpinner isVisible={isLoading} />
    </Container>
  );
};

export default LoginPageView;
