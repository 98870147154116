import { GetServerSideProps } from 'next';
import Head from 'next/head';

import LoginPageTemplate from '@/view/login';

type Props = {
  userEmail?: string | null;
};

const LoginPageV2 = ({ userEmail }: Props) => {
  return (
    <>
      <Head>
        <title>Trade-In Doji - Log In</title>
      </Head>
      <LoginPageTemplate userEmail={userEmail as string} />
    </>
  );
};

export default LoginPageV2;

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const query = ctx.query;

  return {
    props: {
      userEmail: query?.email ?? '',
    },
  };
};
