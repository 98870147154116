import { forwardRef, useState } from 'react';
import { useIntl } from 'react-intl';

import InputWithLabel from '@/components/input-with-label';

import * as A from './assets';

type Props = {
  errorMessage?: string;
  className?: string;
  label?: string;
};

const prefix = 'login.form.password';

const InputPassword = forwardRef<HTMLInputElement, Props>(function InputLabel(
  { errorMessage, className, label, ...props },
  ref
) {
  const { formatMessage } = useIntl();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <InputWithLabel
      {...props}
      ref={ref}
      label={label || formatMessage({ id: `${prefix}` })}
      className={className}
      placeholder={formatMessage({ id: `${prefix}.placeholder` })}
      type={showPassword ? 'text' : 'password'}
      errorMessage={errorMessage}
      autoComplete="current-password"
      isRequired>
      <span className={`cursor-pointer`} onClick={() => setShowPassword(!showPassword)}>
        {showPassword ? <A.ClosedIconEye /> : <A.IconEye />}
      </span>
    </InputWithLabel>
  );
});

export default InputPassword;
