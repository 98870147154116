export const IconEye = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_527_1752)">
        <path
          d="M12 3C17.392 3 21.878 6.88 22.819 12C21.879 17.12 17.392 21 12 21C6.608 21 2.122 17.12 1.181 12C2.121 6.88 6.608 3 12 3ZM12 19C14.0395 18.9996 16.0184 18.3068 17.6129 17.0352C19.2073 15.7635 20.3229 13.9883 20.777 12C20.3212 10.0133 19.2049 8.24 17.6106 6.97003C16.0163 5.70005 14.0383 5.00853 12 5.00853C9.9617 5.00853 7.98369 5.70005 6.38938 6.97003C4.79506 8.24 3.67877 10.0133 3.223 12C3.6771 13.9883 4.79267 15.7635 6.38714 17.0352C7.98161 18.3068 9.96053 18.9996 12 19ZM12 16.5C10.8065 16.5 9.66193 16.0259 8.81802 15.182C7.97411 14.3381 7.5 13.1935 7.5 12C7.5 10.8065 7.97411 9.66193 8.81802 8.81802C9.66193 7.97411 10.8065 7.5 12 7.5C13.1935 7.5 14.3381 7.97411 15.182 8.81802C16.0259 9.66193 16.5 10.8065 16.5 12C16.5 13.1935 16.0259 14.3381 15.182 15.182C14.3381 16.0259 13.1935 16.5 12 16.5ZM12 14.5C12.663 14.5 13.2989 14.2366 13.7678 13.7678C14.2366 13.2989 14.5 12.663 14.5 12C14.5 11.337 14.2366 10.7011 13.7678 10.2322C13.2989 9.76339 12.663 9.5 12 9.5C11.337 9.5 10.7011 9.76339 10.2322 10.2322C9.76339 10.7011 9.5 11.337 9.5 12C9.5 12.663 9.76339 13.2989 10.2322 13.7678C10.7011 14.2366 11.337 14.5 12 14.5Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_527_1752">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ClosedIconEye = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M17.883 19.2966C16.1239 20.4123 14.0831 21.0031 12 20.9996C6.60801 20.9996 2.12201 17.1196 1.18201 11.9996C1.61169 9.67088 2.78256 7.54347 4.52001 5.93455L1.39401 2.80755L2.80801 1.39355L22.607 21.1916L21.193 22.6066L17.883 19.2966ZM5.93601 7.34955C4.57664 8.58501 3.6296 10.2082 3.22301 11.9996C3.5353 13.366 4.16227 14.6407 5.05401 15.7221C5.94575 16.8036 7.07763 17.6619 8.35956 18.2289C9.64149 18.7958 11.0381 19.0556 12.4381 18.9877C13.8381 18.9197 15.203 18.5259 16.424 17.8376L14.396 15.8096C13.5327 16.3534 12.5102 16.5877 11.4962 16.474C10.4823 16.3603 9.53705 15.9055 8.81558 15.184C8.09411 14.4625 7.63923 13.5173 7.52557 12.5033C7.4119 11.4894 7.64619 10.4669 8.19001 9.60355L5.93601 7.34955ZM12.914 14.3276L9.67201 11.0866C9.49407 11.5395 9.45219 12.0345 9.55151 12.5109C9.65083 12.9873 9.88702 13.4243 10.2311 13.7684C10.5752 14.1125 11.0123 14.3487 11.4887 14.448C11.9651 14.5474 12.4611 14.5055 12.914 14.3276ZM20.806 16.5926L19.375 15.1616C20.0442 14.209 20.5208 13.1349 20.778 11.9996C20.5063 10.8092 19.9953 9.68649 19.2761 8.69975C18.5569 7.71302 17.6446 6.88285 16.5945 6.25971C15.5445 5.63658 14.3787 5.23349 13.168 5.07497C11.9573 4.91644 10.7271 5.00578 9.55201 5.33755L7.97401 3.75955C9.22101 3.26955 10.58 2.99955 12 2.99955C17.392 2.99955 21.878 6.87955 22.819 11.9996C22.5127 13.6656 21.823 15.2378 20.806 16.5926ZM11.723 7.50855C12.3595 7.46922 12.9971 7.56562 13.5935 7.79137C14.19 8.01712 14.7316 8.36707 15.1826 8.81801C15.6335 9.26895 15.9834 9.81058 16.2092 10.407C16.4349 11.0034 16.5313 11.641 16.492 12.2776L11.723 7.50855Z"
      fill="#0C0C0C"
    />
  </svg>
);
